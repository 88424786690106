import React from "react";
import { Link } from "react-router-dom";
import useCategory from "../hooks/useCategory";
import Layout from "../components/Layout/Layout";
const Categories = () => {
  const categories = useCategory();
  return (
    <Layout title={"All Categories"}>
      <div
        className="container"
        style={{ marginTop: "100px", backgroundColor: "rgb(241, 242, 244)" }}
      >
        <div className="row container">
          {categories.map((c) => (
            <div className="col-md-4 mt-5 mb-3 gx-3 gy-3" key={c._id}>
              <div className="card bg-light bg-gradient">
                <Link
                  to={`/category/${c.slug}`}
                  className="btn p-4"
                  style={{
                    background: "#fff",
                    transition: "background 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.background = "#99aab5")}
                  onMouseOut={(e) => (e.target.style.background = "#fff")}
                >
                  {c.name}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Categories;
